import { DEFAULT_SORT } from './models/filter';

export const BASE_CATALOG_PARAMS_PATH = '/catalog';

export const DEFAULT_CATALOG_PATH = '/catalog';
export const ENROLLMENTS_CATALOG_PATH = '/catalog?scope=enrolled';
export const SEARCH_RESULTS_PATH = '/catalog?sort=relevance';

export const DEFAULT_PAGE_PARAMS: Record<string, string> = {
  scope: 'all',
  price: 'any-price',
  school: 'any-school',
  skill: 'any-skill',
  difficulty: 'any-difficulty',
  duration: 'any-duration',
  type: 'any-type',
  sort: DEFAULT_SORT,
  page: 'page-1',
};
