import { useRouter } from 'next/router';

import { DEFAULT_CATALOG_PATH } from '~/features/catalog/constants';
import { useUser } from '~/features/user/hooks/use-user';
import { extractCompanySlug } from '~/utils/extract-company-slug';
/**
 * Generate a catalog path based on the provided path and the company slug from the router query.
 * Default path is DEFAULT_CATALOG_PATH.
 *
 * Usage:
 * const catalogPath = useCatalogPath();
 * // catalogPath will be '/catalog' or '/org/[companySlug]/catalog' based on the router query.
 */

export function useCatalogPath(path: string = DEFAULT_CATALOG_PATH) {
  const router = useRouter();
  const { userCatalog } = useUser();
  const companySlug = router.query.companySlug ?? extractCompanySlug(userCatalog?.catalogUrl);

  return companySlug ? `/org/${companySlug}${path}` : path;
}
