import { useQuery } from 'react-query';

import auth from '@udacity/ureact-hoth';

import { getEnterpriseCompanyData } from '~/features/catalog/controllers/get-enterprise-company-data';

export function useEnterpriseCompanyData(companySlug?: string) {
  const jwt = auth.getJWT();

  return useQuery(
    ['enterpriseCompanyData', companySlug],
    async () => {
      if (!companySlug || !jwt) {
        return { name: undefined, logoUrl: undefined };
      }
      return getEnterpriseCompanyData(companySlug, jwt);
    },
    {
      enabled: Boolean(companySlug && jwt),
    }
  );
}
