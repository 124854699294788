import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { AnimatePresence, motion } from 'framer-motion';
import { useQuery } from 'react-query';

import { Box } from '@chakra-ui/react';
import { useDebouncedState } from '@react-hookz/web';
import { ArrowRightIcon } from '@udacity/chakra-uds-icons';
import { headerHeight, useHeaderContext } from '@udacity/udacity-header';

import { Alert } from '~/components/alert';
import { ButtonLink } from '~/components/button-link';
import { ContentContainer } from '~/components/container';
import { Link } from '~/components/link';
import { SEARCH_RESULTS_PATH } from '~/features/catalog/constants';
import { useCatalogPath } from '~/hooks/use-catalog-path';

import { CatalogCardRow } from './catalog-card-row';
import { PersonalizedProgramRecommendationsProps } from './personalized-program-recommendations';

const PersonalizedProgramRecommendations = dynamic<PersonalizedProgramRecommendationsProps>(() =>
  import('./personalized-program-recommendations').then((mod) => mod.PersonalizedProgramRecommendations)
);

export function SearchResults() {
  const searchValue = useHeaderContext((s) => s.searchValue);
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebouncedState(searchValue, 500, 2000);
  const router = useRouter();
  const companySlug = router.query.companySlug ? (router.query.companySlug as string) : undefined;
  const searchResultsPath = useCatalogPath(SEARCH_RESULTS_PATH);

  setDebouncedSearchValue(searchValue);

  const {
    data: searchResults,
    isLoading: searchResultsIsLoading,
    isFetched: searchResultsIsFetched,
  } = useQuery(
    ['search', debouncedSearchValue],
    async () => {
      // Note: we don't need to load algolia until search is used.
      const { getSearchResults } = await import('../controllers/get-search-results');
      return getSearchResults(debouncedSearchValue, companySlug);
    },
    {
      enabled: Boolean(debouncedSearchValue),
    }
  );

  return (
    <ContentContainer
      height={{ base: `calc(100vh - ${headerHeight})`, md: 'auto' }}
      minHeight='400px'
      overflow={{ base: 'auto', md: 'initial' }}
      py='40px'
    >
      <AnimatePresence mode='popLayout'>
        {!searchResultsIsFetched && !searchResultsIsLoading && (
          <Box
            key='search-recommended'
            animate={{ opacity: 1 }}
            as={motion.div}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <PersonalizedProgramRecommendations title='Recommended' />
          </Box>
        )}

        {searchResults && Boolean(searchResults.searchResultItems?.length) && !searchResultsIsLoading && (
          <Box
            key='search-results'
            animate={{ opacity: 1 }}
            as={motion.div}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <CatalogCardRow
              catalogItems={searchResults.searchResultItems}
              title={`Results for ${debouncedSearchValue}`}
            />

            <Box display={{ base: 'none', md: 'block' }} mt='8px'>
              <Link
                _hover={{ color: 'blue.700' }}
                _visited={{ color: 'blue.500', _hover: { color: 'blue.700' } }}
                color='blue.500'
                href={`${searchResultsPath}&searchValue=${debouncedSearchValue}`}
              >
                All Results <ArrowRightIcon h={6} w={6} />
              </Link>
            </Box>

            <Box display={{ base: 'block', md: 'none' }} mt='24px'>
              <ButtonLink
                linkProps={{ href: `${searchResultsPath}&searchValue=${debouncedSearchValue}` }}
                buttonProps={{
                  rightIcon: <ArrowRightIcon h='24px' w='24px' />,
                  size: 'icon-right',
                  width: { base: '100%', md: 'fit-content' },
                }}
              >
                All Results
              </ButtonLink>
            </Box>
          </Box>
        )}

        {searchResultsIsLoading && (
          <Box
            key='search-skeletons'
            animate={{ opacity: 1 }}
            as={motion.div}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <CatalogCardRow isLoading={searchResultsIsLoading} title={`Results for ${debouncedSearchValue}`} />
          </Box>
        )}

        {!searchResultsIsLoading && searchResultsIsFetched && !searchResults?.searchResultItems?.length && (
          <Box
            key='search-no-results'
            animate={{ opacity: 1 }}
            as={motion.div}
            exit={{ opacity: 0 }}
            flex={1}
            initial={{ opacity: 0 }}
            width='100%'
          >
            <Alert status='info'>No Results Found</Alert>
            <Box h='40px' />
            <PersonalizedProgramRecommendations title='Popular' />
          </Box>
        )}
      </AnimatePresence>
    </ContentContainer>
  );
}
