import { useEffect, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import debounce from 'lodash-es/debounce';
import snakeCase from 'lodash-es/snakeCase';
import { useTranslation } from 'react-i18next';

import { Box } from '@chakra-ui/react';
import {
  Actions,
  getAuthenticatedNavItems,
  getSettingsMenuItems,
  getUnauthenticatedNavItems,
  headerHeight,
  MobileMenu,
  Navigation,
  ReferrerData,
  Search,
  SettingsMenuItem,
  UdacityHeader,
} from '@udacity/udacity-header';
import auth from '@udacity/ureact-hoth';

import { trackEvent } from '~/features/analytics/track-event';
import { SEARCH_RESULTS_PATH } from '~/features/catalog/constants';
import { USER_TYPE_COOKIE } from '~/features/cookies/constants';
import { deleteAuthedUserCookies } from '~/features/cookies/utils/delete-authed-user-cookies';
import { getActiveSubscriptionCookie } from '~/features/cookies/utils/get-active-subscription-cookie';
import { getUserTypeCookie } from '~/features/cookies/utils/get-user-type-cookie';
import { setClientCookie } from '~/features/cookies/utils/set-client-cookie';
import { publicEnv } from '~/features/environment/public';
import { useIsConnectLearner } from '~/features/sessions/hooks/use-is-connect-learner';
import { useUser } from '~/features/user/hooks/use-user';
import { useUserExperience } from '~/features/user/hooks/use-user-experience';
import { useBaseUrl } from '~/hooks/use-base-url';
import { useCatalogPath } from '~/hooks/use-catalog-path';
import { useClientRender } from '~/hooks/use-client-render';
import { useEnterpriseCompanyData } from '~/hooks/use-enterprise-company-data';
import { extractCompanySlug } from '~/utils/extract-company-slug';

import { SearchResults } from './search-results';

export function PageHeader({ isSimpleMode }: { isSimpleMode?: boolean }) {
  const { t } = useTranslation();
  const router = useRouter();
  const baseUrl = useBaseUrl();
  const { user, userProfileData, userCatalog, userType, jwt, userIsLoading, userProfileIsLoading } = useUser();
  const userHasAllAccess = getActiveSubscriptionCookie();
  const { isConnectLearner } = useIsConnectLearner();
  const { userExperience } = useUserExperience();
  const clientRender = useClientRender();
  const searchResultsPath = useCatalogPath(SEARCH_RESULTS_PATH);
  const companySlug = (router.query.companySlug as string) ?? extractCompanySlug(userCatalog?.catalogUrl);
  const shouldHideSearch = userExperience?.hasFocused || userCatalog?.catalogUrl?.startsWith('https://emc');

  const { data: enterpriseCompanyData } = useEnterpriseCompanyData(companySlug);
  const enterpriseCompanyLogoUrl = enterpriseCompanyData?.logoUrl;
  const enterpriseCompanyName = enterpriseCompanyData?.name;

  useEffect(() => {
    if (router.query.companySlug && getUserTypeCookie() !== 'enterprise') {
      setClientCookie(USER_TYPE_COOKIE, 'enterprise');
    }
  }, [router.query.companySlug]);

  const activeRouteId = router.route === '/' || router.route === '/dashboard' ? 'home' : router.route.replace('/', '');

  const authenticatedNavItems = getAuthenticatedNavItems({
    baseUrl,
    activeId: activeRouteId,
    isUserSubscribed: Boolean(userHasAllAccess),
    dashboardUrl: '/dashboard',
    enterpriseCatalogUrl: userCatalog?.hasEnterpriseCatalog ? userCatalog.catalogUrl ?? undefined : undefined,
    isConnectLearner,
    t,
  });

  const referrerData: ReferrerData | undefined = useMemo(() => {
    return user && userHasAllAccess
      ? {
          programId: '53db30e7-ba1c-4451-920d-13e1c89ba5d9',
          userId: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        }
      : undefined;
  }, [user, userHasAllAccess]);

  const settingsMenuItems = useMemo(() => {
    const defaultSettingsMenuItems = getSettingsMenuItems(
      t,
      baseUrl,
      referrerData,
      userType,
      userExperience?.communityUrl
    );

    const focusedSettingsMenuItems = ['profile', 'notifications', 'logout'];

    if (userExperience?.hasFocused) {
      return defaultSettingsMenuItems.filter((item) => focusedSettingsMenuItems.includes(item.id));
    } else {
      return defaultSettingsMenuItems;
    }
  }, [baseUrl, referrerData, t, userExperience?.hasFocused, userType, userExperience?.communityUrl]);

  function handleSearchEnter(searchValue: string) {
    router.push(`${searchResultsPath}&searchValue=${searchValue}`);
  }

  const isLoading = userIsLoading || userProfileIsLoading;

  if (!clientRender || isLoading) {
    return (
      <>
        <Box bg='white' position='fixed' top={0} width='100%' zIndex={100}>
          <UdacityHeader
            authBaseUrl={publicEnv.NEXT_PUBLIC_AUTH_URL}
            authCallbackUrl={baseUrl}
            classroomUrl={publicEnv.NEXT_PUBLIC_CONNECT_URL}
            isUserAuthenticated={false}
            logoUrl={jwt ? '/dashboard' : '/'}
          />
        </Box>
        <Box mb={headerHeight} />
      </>
    );
  }

  return (
    <>
      <Box bg='white' position='fixed' top={0} width='100%' zIndex={100}>
        <UdacityHeader
          actionsSlot={<Actions />}
          authBaseUrl={publicEnv.NEXT_PUBLIC_AUTH_URL}
          authCallbackUrl={baseUrl}
          authenticatedNavItems={authenticatedNavItems}
          classroomUrl={publicEnv.NEXT_PUBLIC_CONNECT_URL}
          enterpriseCompanyLogoUrl={enterpriseCompanyLogoUrl}
          enterpriseCompanyName={enterpriseCompanyName}
          isSimpleMode={isSimpleMode}
          isUserAuthenticated={Boolean(userProfileData)}
          linkComponent={Link}
          logoUrl={jwt ? '/dashboard' : '/'}
          mobileMenuSlot={<MobileMenu />}
          navigationSlot={userExperience?.hasFocused ? undefined : <Navigation />}
          searchResultsSlot={<SearchResults />}
          searchSlot={shouldHideSearch ? undefined : <Search />}
          settingsMenuItems={settingsMenuItems}
          unauthenticatedNavItems={getUnauthenticatedNavItems(t, baseUrl, activeRouteId)}
          userName={`${user?.firstName} ${user?.lastName}`}
          userProfileImageUrl={userProfileData?.photoUrl}
          onSearchChange={handleSearchChange}
          onSearchEnter={handleSearchEnter}
          onLogInClick={() =>
            trackEvent({
              name: 'Navbar Link Clicked',
              type: 'click',
              label: 'Navbar Link - Header Link Clicked - Log In',
            })
          }
          onNavItemClick={(navItem) => {
            if (navItem.groupTitle) {
              // Note: here the user has clicked on a link item within a group.
              trackEvent({
                type: 'click',
                name: 'Navbar Link Clicked',
                label: navItem.groupTitle,
                action: `navbar_${snakeCase(navItem.groupTitle)}_clicked`,
                value: navItem.label,
              });
            } else {
              trackEvent({
                type: 'click',
                name: 'Navbar Link Clicked',
                label: navItem.label,
                action: 'navbar_clicked',
              });
            }
          }}
          onSettingsMenuItemClick={(settingsMenuItem: SettingsMenuItem) => {
            if (settingsMenuItem.id === 'logout') {
              deleteAuthedUserCookies();

              auth.signOut({
                returnUrl: baseUrl,
              });
            }
            if (settingsMenuItem.id === 'refer-a-friend') {
              trackEvent({
                name: 'Profile Icon Clicked',
                type: 'click',
                label: 'Profile Icon Clicked - Refer a Friend',
              });
            }
          }}
          onSignUpClick={() =>
            trackEvent({
              name: 'Navbar Link Clicked',
              type: 'click',
              label: 'Navbar Link - Header Link Clicked - Sign Up',
            })
          }
        />
      </Box>
      <Box mb={headerHeight} />
    </>
  );
}

const handleSearchChange = debounce(
  (term: string) =>
    trackEvent({
      name: 'Search Bar Clicked',
      type: 'search',
      action: 'key_words_searched',
      value: term,
      label: 'Search',
    }),
  500
);
