// Pulls the companySlug out of a user's catalogUrl if it matches the pattern udacity.com/org/companySlug/catalog

export function extractCompanySlug(catalogUrl: string | null | undefined): string | null {
  if (!catalogUrl) {
    return null;
  }

  const companySlugMatch = catalogUrl.match(/udacity\.com\/org\/([^\/]+)\/catalog/);
  const companySlug = companySlugMatch?.[1];

  return companySlug ?? null;
}
