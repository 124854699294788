import { z } from 'zod';

import { gql } from '~/utils/gql';

const companyDataSchema = z
  .object({
    logoUrl: z.string().nullable(),
    name: z.string(),
  })
  .transform((data) => {
    return {
      logoUrl: data.logoUrl ?? undefined,
      name: data.name,
    };
  });

export const companyDataResponseSchema = z.object({
  data: z.object({
    companyBySlug: companyDataSchema,
  }),
});

export const fetchCompanyData = gql`
  query FetchCompanyData($companySlug: String!) {
    companyBySlug(slug: $companySlug) {
      logoUrl
      name
    }
  }
`;

export type EnterpriseCompanyData = z.infer<typeof companyDataSchema>;
export type EnterpriseCompanyDataResponse = z.infer<typeof companyDataResponseSchema>;
